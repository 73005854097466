const RMSAuth = (() => {
    let accessToken = null;
    const TOKEN_KEY = 'rmsAuthToken';
  
    const auth = async (tenant, location) => {
      const grantType = "access_token";
      const role = "cisco";
      const exp = 8640000;
  
      const response = await fetch('https://rms.dnaspaces.io/oauth/v2/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tenant, location, grantType, role, exp }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    };
  
    const setToken = (token_key,token) => {
      accessToken = token;
      localStorage.setItem(TOKEN_KEY+"_"+token_key, token);
    };
  
    const getToken = (token_key) => {
      return localStorage.getItem(TOKEN_KEY+"_"+token_key);
    };
  
    const getShortUri = (key) => {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
      }
      return item.shortUri;
  
    };
    const setShortUri = (key, value, expiry) => {
  
      const item = {
        shortUri: value,
        expiry:  expiry*1000,
      };
    //  localStorage.setItem(key, JSON.stringify(item));
    };
    const refreshAccessToken = async (tenant, location) => {
      const grantType = "access_token";
      const role = "cisco";
      const exp = 8640000;
  
      try {
        const response = await fetch('https://rms.dnaspaces.io/oauth/v2/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ tenant, location, grantType, role, exp }),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
        setToken(tenant+"_"+location,data.accessToken);
      } catch (error) {
        console.error('Error refreshing token:', error);
      }
    };
  
    return {
      auth,
      setToken,
      getToken,
      refreshAccessToken,
      getShortUri,
      setShortUri
    };
  })();
  
  export default RMSAuth;
  