import React, { useEffect, useState, useRef } from "react";
import MapComponent from "../common-pointr-map-container/GenericPointrMapContainer"
import LeftContainer from "../left-container/left-container";
import HeaderContainer from "../header-container/header-container";
import RightContainer from "../right-container/right-container";
import CountdownTimer from "../countdown-timer-container/countdown-timer-container";
import SearchPopup from '../main-container/SearchPopup'; // Import SearchPopup component
import FooterContainer from '../footer-container/footer-container';
import { useMap } from '../../../context/MapContext'; // Import the useMap hook
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import { handleReset } from '../main-container/handleResetView'; // Import the functions
import { calculateMidPoint } from '../main-container/markCurrentPosition'; // Import the functions
import { fetchGsxData } from '../left-container/fetchZonesHubwalksInfo';
import CustomPopup from '../main-container/DirectionPopup';
import ciscoSpacesPopupIcon from '../../../../assets/images/powered-by-spaces-webexone.png';
import Screensaver from "../countdown-timer-container/screensaver";
import { setLogoBackground } from '../main-container/utilityMethods'
import ciscoSpacesPopupImage from '../../../../assets/images/cisco-space-popup-webexone.png';
const WebexOneMainContainer = () => {
  const { map } = useMap();

  const [isActive, setIsActive] = useState(true);
  const [timer, setTimer] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const [zonesInfo, setZonesInfo] = useState([]);
  const [hubwalksInfo, setHubwalksInfo] = useState([]);
  const [facilitiesData, setFacilitiesData] = useState([]);

  const timerRef = useRef(null);
  const [popupState, setPopupState] = useState({ poi: null, coordinates: null }); // Add state for the popup
  const [ciscoSpacesPopup, setCiscoSpacesPopup] = useState(false);
  const [isPointrSDKLoaded, setIsPointrSDKLoaded] = useState(false); // State to track Pointr SDK load status

  // State for the date object in the parent component
  const [eventStartDateData, setEventStartDateData] = useState({
    year: 2024,
    month: 9,
    day: 21
  });

  const [eventEndDateData, setEventEndDateData] = useState({
    year: 2024,
    month: 9,
    day: 28
  });


  const tenantId = "263252";
  const locationId = "ab86fbb9-570c-4358-9c4f-a09269b184bc";
  const siteExternalIdentifier = "883d756d-8c4d-4994-b385-febf89b5c2b4";
  const timeZoneCity = "Hollywood/Florida";
  const timeZone = "America/New_York";
  const eventLogoName = "webex-one.svg"
  const eventName = "WebexOne"
  const qrCodeExpiresAt = 1735689598
  const eventTitle = "Webex One"
  const eventSubTitle = "Ground Floor"
  const minMaxBounds = [
    [-80.11875108032157,
      25.990432415232092],
    [-80.11698430626392,
      25.993652513535437]
  ]
  const centerLatLong =
  [
    -80.11794702895338,
    25.99179221344903
  ]
  
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const lat = query.get('lat') ? query.get('lat') : 25.991702124604515;
  const long = query.get('long') ? query.get('long') : -80.118019546699276;
  const rotation = query.get('rotation') ? query.get('rotation') : -2.1;
  const zoom = query.get('zoom') ? query.get('zoom') : 19.5;
  const mapMinZoom = query.get('mapMinZoom') ? query.get('mapMinZoom') : 19;
  const [building, setBuilding] = useState(parseInt(query.get('building')) || 7);
  const [whereAmILevel, setWhereAmILevel] = useState(query.get('level') || 0);
  const [level, setLevel] = useState(query.get('level') || 0);
  //const building = query.get('building') ? query.get('building') : 2;
  const [whereAmIBuilding, setWhereAmIBuilding] = useState(query.get('building') ||7);
  const pitch = query.get('pitch') ? query.get('pitch') : 65;
  const bearing = query.get('bearing') ? query.get('bearing') : 89.7;
  const showPopup = (poi, coordinates, cleanPopup = true) => {
    if (!poi) {
      setPopupState({ poi: null, coordinates: null });
      handleResetView(cleanPopup);
    } else {
      setPopupState({ poi, coordinates: (poi?.geometry?.type == 'Point') ? poi.geometry.coordinates : calculateMidPoint(poi.geometry.coordinates[0]) });
    }
  };
  const showCiscoSpacesPopup = () => {
    setCiscoSpacesPopup(true);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (window.PointrWebSDK) {
        setIsPointrSDKLoaded(true);
        clearInterval(interval);
      }
    }, 100); // Check every 100ms

    return () => clearInterval(interval);
  }, []);
  // Handler to hide the popup
  const hidePopup = () => {
    setPopupState({ poi: null, coordinates: null });
    handleResetView();
  };

  useEffect(() => {
    // Fetch data from the API only once when the component mounts
    const getZones = async () => {
      try {

        const data = await fetchGsxData(tenantId);
        console.log("Webexone" + data);

        setZonesInfo([]);
        setHubwalksInfo(data.hubWalks);
        setFacilitiesData(data.facilities)
      } catch (err) {

        console.log(err);

      }
    };

    getZones();
  }, []);

  // Simulate map loading
  useEffect(() => {
    // Simulate a delay for the map loading
    const mapLoadTimeout = setTimeout(() => {
      setIsMapLoaded(true);
      document.title = '2024 WebexOne';

    }, 5000); // Adjust the delay as needed

    return () => clearTimeout(mapLoadTimeout);
  }, []);


  useEffect(() => {
    const eventTypes = ["click", "mousemove", "keypress", "wheel", "gesturestart", "gesturechange", "gestureend", "touchmove", "touchstart", "touchend"];

    eventTypes.forEach(eventType => {
      window.addEventListener(eventType, handleActivity);
    });

    return () => {
      eventTypes.forEach(eventType => {
        window.removeEventListener(eventType, handleActivity);
      });
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => { }, [isActive]);


  const handleActivity = (event) => {
    setIsActive(true);
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setIsActive(false);
      console.log("No activity detected for 2 minutes." + event.type);
    }, 180000); // 2 minutes
  };


  const handleSearch = async (searchInput) => {
    hidePopup();
    const uiController = map.getUiController();
    const mapViewController = uiController.getMapViewController();
    const mapView = mapViewController.getView();
    mapView.setLayoutProperty("rmaps-symbol_selected_ptr", 'icon-image', 'green_marker');

    console.log(searchInput);
    let valueAfterTilde;
    let poiFid = searchInput?.fId;
    if (searchInput?.type == 'session') {
      poiFid = searchInput?.poiFId;

    }
    if (poiFid?.includes('~')) {
      valueAfterTilde = poiFid.split('~')[1];
    } else {
      valueAfterTilde = poiFid;
    }
    const poi = await map.getPoiManager().get(valueAfterTilde);
    if (poi) {
      map.unhighlight();
      mapViewController.highlightPoi(poi);
      map.hidePoiDetails(poi);

      if (poi?._geometry?.coordinates[0]) {
        const latLong = (poi.geometry.type == 'Point') ? {
          lat: poi.geometry.coordinates[1],
          lng: poi.geometry.coordinates[0]
        } : calculateMidPoint(poi?._geometry?.coordinates[0]);

        //updateLayerFilterForSingleFeature(mapView, searchInput);
        mapView.map.flyTo({
          center: [latLong.lng, latLong.lat],
          zoom: 20, pitch: pitch, bearing: bearing, speed: 0.4
        });
        if (searchInput.type == 'session') {
          poi._properties.isSession = true;
          poi._properties.sessionsInfo = [searchInput];
        }
        showPopup(poi, latLong);

      }
    }
  };

  useEffect(() => {
    if (searchInput) {
      handleSearch(searchInput);
    }
  }, [searchInput]);

  const handleResetView = (cleanPopup = false) => {
    handleReset(long, lat, rotation ? rotation : -4.5, zoom ? zoom : 22, true, cleanPopup, pitch, bearing, whereAmIBuilding, whereAmILevel);
  };


  const handleResetViewButton = (cleanPopup = false) => {
    setPopupState({ poi: null, coordinates: null });
    handleResetView();
  };

  return (
    <div className="main-container">

      {isPointrSDKLoaded && <MapComponent showPopup={showPopup} tenantId={tenantId}
        locationId={locationId} siteExternalIdentifier={siteExternalIdentifier}
        lat={lat} long={long} zoom={zoom} pitch={pitch} bearing={bearing} rotation={rotation} mapMinZoom={mapMinZoom} eventName={eventName} minMaxBounds={minMaxBounds} whereAmILevel={whereAmILevel} whereAmIBuilding={whereAmIBuilding} defaultBuilding={building} defaultLevel={level}  timeZone={timeZone} eventStartDateData={eventStartDateData}/>}
      {isMapLoaded && (
        <>
          <HeaderContainer
            showCiscoSpacesPopup={showCiscoSpacesPopup} timeZoneCity={timeZoneCity} 
            eventStartDateData={eventStartDateData} eventEndDateData={eventEndDateData} 
            handleResetView={handleResetViewButton} eventLogoName={eventLogoName} 
            defaultLevel={level} defaultBuilding={building} setBuilding={setBuilding} 
            setLevel={setLevel} pitch={pitch} bearing={bearing} lat={lat} 
            long={long} rotation={rotation}
            eventTitle={eventTitle}
            eventSubTitle={eventSubTitle}
            eventTimeZone={timeZone}/>
          {zonesInfo &&
            <LeftContainer
              zoneData={zonesInfo} showPopup={showPopup} hidePopup={hidePopup} tenantId={tenantId}
              locationId={locationId} qrCodeExpiresAt={qrCodeExpiresAt} 
              whereAmIBuilding={whereAmIBuilding}
              whereAmILevel={whereAmILevel}
              defaultLevel={parseInt(level)} 
              defaultBuilding = {parseInt(building)}
              handleResetView={handleResetView}
              setBuilding={setBuilding}
              setLevel={setLevel} 
              lat={lat}
              long={long}
              rotation={rotation}
              showLevelSelector={false}/>}
          {isPointrSDKLoaded && <RightContainer
            hubwalksData={hubwalksInfo} facilitiesData={facilitiesData} pitch={pitch} bearing={bearing}/>}
          {(!isActive &&
            /**<CountdownTimer
              initialMinutes={0}
              initialSeconds={10}
              resetTimer={isActive} // Pass isActive as a prop to reset the timer
              showPopup={showPopup}
            />*/
            <Screensaver inactivityTimeout={1000} flipInterval={3000} showPopup={showPopup} ciscoSpacesPopupImage={ciscoSpacesPopupImage} lat={lat} long={long} zoom={zoom} rotation={rotation}/>

          )}
          {showSearch && (
            <SearchPopup
              setSearchInput={setSearchInput}
              setShowSearch={setShowSearch}
              map={map}
              tenantId={tenantId}
            />
          )}
          {popupState.poi && (
            <CustomPopup
              poi={popupState.poi}
              coordinates={popupState.coordinates}
              tenantId={tenantId}
              locationId={locationId}
              onCancel={hidePopup}
              source={"poiClick"}
              lat={lat}
              long={long}
              level={level}
              rotation={rotation}
              qrCodeExpiresAt={qrCodeExpiresAt}
              isZonesPopulated={false}
              eventStartDate={eventStartDateData}
            />
          )}
          {ciscoSpacesPopup && (
            <div className="cisco-spaces-popup-overlay" >
              <div className="cisco-spaces-popup-overlay">
                <div className="cisco-spaces-popup-overlay-popup">
                  <img src={ciscoSpacesPopupIcon} alt="Popup Content" class="cisco-spaces-popup-overlay-image" />
                  <div className="cisco-spaces-popup-overlay-close" onClick={() => setCiscoSpacesPopup(false)}>
                    <svg width="44" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_11306_4187)">
                        <circle cx="22.5477" cy="22.1922" r="21.1342" stroke="white" stroke-width="0.98722" />
                        <path d="M29.4141 26.9647L24.6432 22.1929L29.4141 17.4216L27.3198 15.3271L22.5486 20.0983L17.7775 15.3271L15.683 17.4216L20.4544 22.1929L15.6821 26.9647L17.7764 29.0589L22.5486 24.287L27.3198 29.0589L29.4141 26.9647Z" fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0_11306_4187">
                          <rect width="43.2556" height="43.2556" fill="white" transform="translate(0.919922 0.564453)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>

              </div>
            </div>
          )}
          <FooterContainer
            setShowSearch={setShowSearch}
            handleResetView={handleResetViewButton}
            long={long}
            lat={lat}
            rotation={rotation}
            pitch={pitch}
            bearing={bearing}
            whereAmIBuilding={whereAmIBuilding}
            whereAmILevel={whereAmILevel}
          />
          

        </>
      )}
    </div>
  );
};

export default WebexOneMainContainer;
