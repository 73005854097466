import React from 'react';
import WebexOneMainContainer from './WebexOneMainContainer';

const WebexOneFullPageContainer = () => {
  return (
    <div className="full-page-container">
       <WebexOneMainContainer />
    </div>
  );
};

export default WebexOneFullPageContainer;
