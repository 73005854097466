import axios from 'axios';

export const fetchZones = async (tenantId) => {
  try {
    const response = await axios.get(`${window.env.WF_CDN_BACKEND_API_URL}/sites/${tenantId}/zones.json`);
    return response.data.zones;
  } catch (err) {
    throw err;
  }
};

export const fetchHubwalks = async (tenantId) => {
    try {
      const response = window.env.WF_CDN_BACKEND_API_URL? axios.get(`${window.env.WF_CDN_BACKEND_API_URL}/sites/${tenantId}/zones.json`): await axios.get(`${window.env.BACKEND_API_URL}/wayfinder/gsx/api/${tenantId}/gsx-data`);
      return response.data.hubwalks;
    } catch (err) {
      throw err;
    }
  };

  export const fetchGsxData = async (tenantId) => {
    try {
      var response = null;
      if (window.env.WF_CDN_BACKEND_API_URL) {
        const zonesResponse = await fetch(`${window.env.WF_CDN_BACKEND_API_URL}/sites/${tenantId}/zones.json`, {
          method: 'GET',
        });
        const zones = await zonesResponse.json(); // Parse zones data

        // Fetch categories data
        const categoriesResponse = await fetch(`${window.env.WF_CDN_BACKEND_API_URL}/sites/${tenantId}/categories.json`, {
          method: 'GET',
        });
        const facilities = await categoriesResponse.json(); // Parse categories data

        // Combine into a single response object with both zones and categories
        response = {
          zones,
          facilities,
        };
      } else {
        response = await fetch(`${window.env.BACKEND_API_URL}/wayfinder/gsx/api/${tenantId}/gsx-data`, {
          method: 'GET',
        });
        response = await response.json();
      }


      console.log("fetchData " + response);
      if (window.env.WF_CDN_BACKEND_API_URL) {
        return response;
      } else {
        return response.result;
      }
    } catch (err) {
      throw err;
    }
  };