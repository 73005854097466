import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMap } from '../../../context/MapContext';
import { markCurrentPosition, calculateMidPoint, manageLayers } from '../main-container/markCurrentPosition';
import { updateLayerFilterForSingleFeature, resetBoothLayerFilter } from '../main-container/layerUtils';
import { fetchSessionDetails } from '../main-container/searchApis';
import { getStartAndEndOfDayInUTC } from '../main-container/DateTimeUtils';
import { sendTrackingEventForMapClick, sendTrackingEventForZoomChange, sendTrackingEventForMapMoveEnd } from '../main-container/pendoApis';
const MapComponent = ({ showPopup, tenantId, locationId, siteExternalIdentifier,lat, long, zoom, rotation, pitch, bearing, mapMinZoom = 17, eventName ,minMaxBounds, centerLatLong, whereAmILevel, whereAmIBuilding, defaultBuilding, defaultLevel, setBuilding, setLevel, timeZone, eventStartDateData }) => {
  const { setMap } = useMap();
  const mapBoxRef = useRef(null);
  const pointrWebRef = useRef(); // Store the Pointr SDK instance
  const mapViewControllerWebRef = useRef(null); // Store the Pointr SDK instance

  const handleLayerUpdate = async (mapView, feature) => {
    updateLayerFilterForSingleFeature(mapView, feature);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        window.pendo.initialize({
          visitor: {
            id: "user_events.ciscospaces.io"

          },
          account: {
            id: "account_events.ciscospaces.io"

          }
        });



        const reqBody = JSON.stringify({ tenantId, locationId });
        const url = `${window.env.BACKEND_API_URL}/wayfinder/api/v8/generate/client-id`;
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        };

        const response = await fetch(url, {
          method: 'POST',
          headers,
          body: reqBody,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const clientKey = data.result.client_key;

        // Base options configuration
        let optionsConfig = {
          container: "pointr-container",
          apiUrl: `${window.env.BACKEND_API_URL}/wayfinder`,
          clientInternalIdentifier: clientKey,
          clientSecret: "1d55b70c-bff8-41c1-9653-ec99003f01af",
          initialZoomLevel: zoom,
          mapMinZoom: mapMinZoom,
          mapMaxZoom: 23,
          shouldEnableSearch: false,
          shouldEnableZoomControls: false,
          siteExternalIdentifier: siteExternalIdentifier
        };
        
        // Add center if centerLatLong is available
        if (centerLatLong) {
          optionsConfig.center = centerLatLong;
        }
      /**
        // Create the options object
        if (buildingExternalIdentifier) {
          optionsConfig.buildingExternalIdentifier=buildingExternalIdentifier;
        }
        if(levelIndex){
          optionsConfig.levelIndex=levelIndex;
        } */

        const options = new window.PointrWebSDK.Options(optionsConfig);

        // const pointrWeb = new window.PointrWebSDK.MapWidget(options);

        let pointrWeb;

        // Check if window.PointrWebSDK, PointrWebSDK.MapWidget, and MapWidget.getInstance are available
        if (window.PointrWebSDK &&
          window.PointrWebSDK.MapWidget &&
          typeof window.PointrWebSDK.MapWidget.getInstance === 'function') {

          // Check if an instance already exists
          const existingInstance = window.PointrWebSDK.MapWidget.getInstance();

          if (existingInstance) {
            pointrWeb = existingInstance;
          } else {
            // Create a new instance if none exists
            pointrWeb = new window.PointrWebSDK.MapWidget(options);
          }
        } else {
          pointrWeb = new window.PointrWebSDK.MapWidget(options);
        }
        pointrWeb.start();
        pointrWebRef.current = pointrWeb;

        const uiController = pointrWeb.getUiController();
        const mapViewController = uiController.getMapViewController();
        const mapView = mapViewController.getView();
        const events = mapView.events;
        const navigationViewController = pointrWeb.getUiController().getNavigationViewController();
        const navigationView = navigationViewController.getView();

        mapViewControllerWebRef.current = mapViewController;

        mapView.on(events.mapReady, (data) => {
          let pointrLayers = [];
          mapView.getAllLayers().filter((layer) => {
            if (layer.id.includes("_ptr")) {
              pointrLayers.push(layer);
            }
          });
         /** mapView.on(events.levelChanged, (newlevel) => {
             
            //  manageLayers(mapView, null, newlevel);

           mapView.getAllLayers().filter(function (layer) {
              let filter = layer.filter;
              if (filter) {
                let lvlFilter = filter.pop();
                let bidFilter = filter.pop();
                if (!Array.isArray(bidFilter) || (!(bidFilter.includes("bid") && lvlFilter.includes("==")))) {
                  filter.push(bidFilter);
                }
                if (!(lvlFilter.includes("lvl") && lvlFilter.includes("=="))) {
                  filter.push(lvlFilter);
                } else {
                  return layer.id;
                }
              }
            }).forEach(function (layer) {
              layer.filter.push(["==", "lvl", newlevel]);
              if (layer.id.includes("-highlight")) {
                layer.layout.visibility = "none";
              } else if (!layer.id.includes("-selected")) {
                layer.layout.visibility = "visible";
              }
              mapView.removeLayer(layer["id"]);
              mapView.addLayer(layer);
            });
            pointrLayers.forEach((layer) => {
              mapView.removeLayer(layer["id"]);
              layer.layout.visibility = "visible";
              mapView.addLayer(layer);
            });
            
            mapView.map.flyTo({
              center: [mapView.map.getCenter().lng, mapView.map.getCenter().lat],
              zoom: zoom, pitch: pitch, bearing: bearing, speed: 0.4,
              curve: 1.42, // Adjust the curve (default is 1.42)
              easing: (t) => t, // Optional: customize the easing function
            });
             markCurrentPosition(pointrWeb, long, lat, rotation, false, pitch, bearing);

          }); */
        });


        mapView.on(mapView.events.mapReady, () => {
          mapViewController.setBearing(bearing);
          mapViewController.setPitch(pitch);
          mapView.map.flyTo({
            center: [mapView.map.getCenter().lng, mapView.map.getCenter().lat],
            zoom: zoom, pitch: pitch, bearing: bearing, speed: 0.4
          });
          markCurrentPosition(pointrWeb, long, lat,rotation ,false ,pitch ,bearing ,whereAmIBuilding ,whereAmILevel ,defaultBuilding ,defaultLevel );
          /**
           * const latlng = new window.PointrWebSDK.LatLng({ lat: lat, lng: long });
           */

          if(minMaxBounds)
            mapView.map.setMaxBounds(minMaxBounds);

        });
 
        pointrWeb.on(pointrWeb.events.dataLoaded, () => {
          mapView.on(events.mapClicked, async function (features) {
            //if(pointrWeb?.siteBuildingManager?.getAllBuildings()<=1)
              showPopup(null, null, true, true);
            mapViewController.removeAllCustomPointMarkersFromMap();
            mapView.setLayoutProperty("rmaps-symbol_selected_ptr", 'icon-image', 'green_marker');
            const priorityList = ['rmaps-booth-marker','rmaps-registration-marker','rmaps-meeting-space-marker','rmaps-registration-covers','rmaps-entryexit-marker','rmaps-cisco-spaces-marker','rmaps-lounges-marker', 'rmaps-spaces', 'rmaps-symbol_landmark', 'rmaps-hub-theatre-cover', 'rmaps-theatre-marker', 'rmaps-theatre-covers', 'rmaps-restrooms-marker','rmaps-toilets-marker','rmaps-poi-stairs','rmaps-poi-lift','rmaps-event-other-marker', 'rmaps-park-garden-cover','rmaps-cafes-covers', 'rmaps-other-covers', 'rmaps-all-other-items', 'rmaps-booth-cover','rmaps-pavement','rmaps-zone-marker','rmaps-solid-wall','rmaps-floor-outline', 'rmaps-zone-cover'];
            let filteredFeatures = features.sort((a, b) => {
              const indexA = priorityList.indexOf(a.layer.id);
              const indexB = priorityList.indexOf(b.layer.id);

              // Handle cases where layer.id is not in priorityList
              if (indexA === -1) return 1;
              if (indexB === -1) return -1;

              return indexA - indexB;
            }).filter(feature => {
              // Remove 'rmaps-zone-cover' if zoom level is 18.5 or higher
              if (feature.layer.id === 'rmaps-zone-cover' && feature.properties.name === 'Keynote Zone') {
                return true;
              }
              if (feature.layer.id === 'rmaps-zone-cover' && mapView.map.getZoom() > 19) {
                return false;
              }
              // Remove 'rmaps-solid-wall' and 'rmaps-floor-outline'
              if (feature.layer.id === 'rmaps-solid-wall' || feature.layer.id === 'rmaps-floor-outline') {
                return false;
              }
              return true;  // Keep all other features
            });
            if(filteredFeatures?.length==1&&(filteredFeatures[0]?.layer.id =='rmaps-zone-cover'||filteredFeatures[0]?.layer.id =='rmaps-booth-cover'||filteredFeatures[0]?.layer.id =='rmaps-zone-marker')){
              return;
            }
            for (let i = 0; i < filteredFeatures.length; i++) {
              const feature = filteredFeatures[i];
              try {
                if(feature.layer.id == 'rmaps-solid-wall'){
                  return;
                }
                if(feature.layer.id =='rmaps-floor-outline'){
                  continue;
                }
                if (feature.properties.fid && feature.properties.name) {
                  let valueAfterTilde;
                  if (feature.properties.fid.includes('~')) {
                    valueAfterTilde = feature.properties.fid.split('~')[1];
                  } else {
                    valueAfterTilde = feature.properties.fid;
                  }
                  const poi = await pointrWeb.getPoiManager().get(valueAfterTilde);
                  if (poi) {

                    //feature.properties.isSelected=true;        
                    if (feature.layer.id == 'rmaps-zone-marker' || feature.layer.id == 'rmaps-zone-cover' || feature.layer.id == 'rmaps-spaces') {

                      const latLong = (poi.geometry.type == 'Point') ? {
                        lat: poi.geometry.coordinates[1],
                        lng: poi.geometry.coordinates[0]
                      } : calculateMidPoint(poi.geometry.coordinates[0]);
                      mapView.map.flyTo({
                        center: [latLong.lng, latLong.lat],
                        zoom: 21.5, pitch: pitch, bearing: bearing, speed: 0.4
                      });
                    } else {

                      // handleLayerUpdate(mapView, feature);

                      pointrWeb.unhighlight();
                      mapViewController.setLevel(poi.properties.lvl);
                      mapViewController.highlightPoi(poi);
                      const latLong = (poi.geometry.type == 'Point') ? {
                        lat: poi.geometry.coordinates[1],
                        lng: poi.geometry.coordinates[0]
                      } : calculateMidPoint(poi.geometry.coordinates[0]);
                      mapView.map.flyTo({
                        center: [latLong.lng, latLong.lat],
                        zoom: 20, pitch: pitch, bearing: bearing, speed: 0.4
                      });
                    }

                    // Example usage for 27th, 28th, and 29th August 2024 relative to today

                    const startEndEpochTimes = getStartAndEndOfDayInUTC(timeZone,eventStartDateData.day);
                    console.log(startEndEpochTimes);
                    const sessionsInfo = await fetchSessionDetails(tenantId, valueAfterTilde, { startEpochMilli: startEndEpochTimes.startEpochMilli, endEpochMilli: startEndEpochTimes.endEpochMilli });
                    if (sessionsInfo.length > 0) {
                      poi._properties.isSession = true;
                      poi._properties.sessionsInfo = sessionsInfo;
                    }

                    showPopup(poi, (poi?.geometry?.type == 'Point') ? poi.geometry.coordinates : calculateMidPoint(poi.geometry.coordinates[0]),true);
                   
                    sendTrackingEventForMapClick(feature, eventName);
                    //createCustomPopup(mapViewController, feature, calculateMidPoint(poi.geometry.coordinates[0]));
                    //navigationViewController.populate(startDestination, poi);
                    //navigationViewController.startNavigation();
                    break;
                  }
                }
              } catch (error) {
                console.error('Fetch error:', error);
              }
            }
            // mapViewController.updateFeatures(mapView.getLayer("rmaps-booth-marker"),filteredFeatures);
          });
        });

        pointrWeb.on(pointrWeb.events.dataLoaded, () => {
          mapView.on(events.zoomChanged, (zoomValue) => {
            sendTrackingEventForZoomChange(zoomValue.toFixed(1), eventName);
          })
        });

        pointrWeb.on(pointrWeb.events.dataLoaded, () => {
          mapView.on(events.mapMoveEnd, () => {
            //sendTrackingEventForMapMoveEnd();
            console.log(" Call back mapMoveEnd ");
          })
        });
        setMap(pointrWeb);

        return () => {
          pointrWeb.destroy(); // Cleanup
        };
      } catch (error) {
        console.error('Fetch error:', error);
      }
    }

    fetchData();
  }, [lat, long, rotation, setMap]);


// Function to get current bounds coordinates
function getCurrentBounds(map) {
  const bounds = map.getBounds();
  const sw = bounds.getSouthWest(); // Southwest corner
  const ne = bounds.getNorthEast(); // Northeast corner

  return [
     [
           sw.lng,
            sw.lat
        ],
       [
           ne.lng,
            ne.lat
      ]
    ];
}

  /*
    // Create the popup and add it to the DOM
  
    function createCustomPopup(mapViewController, feature, lngLat) {
      mapViewController.removeAllMarkers();
       
      const customElement = document.createElement("div");
      customElement.className = "marker";
      customElement.style.backgroundImage = "url(https://pointrapps.blob.core.windows.net/core-web-sdk/sdk-v8/icons/64x64-pamuk.jpg)";
      customElement.style.width = "64px";
      customElement.style.height = "64px";
      // ADD click event to your element
      customElement.addEventListener("click", function () {
        window.alert("Marker clicked");
      });
  
      const popupContent = document.createElement('div');
      popupContent.className = 'poi-popup';
  
      const title = document.createElement('h3');
      title.className = 'poi-popup-title';
      title.textContent = feature.properties.name;
  
      const description = document.createElement('p');
      description.className = 'poi-popup-description';
      description.textContent = feature.properties.description;
  
      const button = document.createElement('button');
      button.className = 'poi-popup-button';
      button.textContent = 'Get Directions';
      button.onclick = () => alert('More Info');
  
      const close = document.createElement('button');
      close.className = 'poi-popup-button';
      close.textContent = 'Get Directions';
      close.onclick = () => mapViewController.removeMarker(myMarker);
  
  
      // Append the elements to the popup content
      popupContent.appendChild(title);
      popupContent.appendChild(description);
      popupContent.appendChild(button);
  
  
  
      const myMarkerFeature = new window.PointrWebSDK.Feature(
        {},
      { type: "Point", coordinates: [lngLat.lng,lngLat.lat] }
      );
  
      const myMarker = mapViewController.addMarker(myMarkerFeature, popupContent);
  
  
      // Add event listener to close the marker when clicking outside
      document.addEventListener('click', handleClickOutside(mapViewController));
      // Create a new popup instance
      //  const popup = mapViewController.getView().addPopup(feature,  '<h3 style="font-size:16px;font-weight:bold;">${feature.properties.name}</h3>', { offset: 1 });
  
      // Optionally, you can add custom classes to the popup for styling
      // popup.getElement().classList.add('poi-popup-container');
      // popup.popup.addClassName('poi-popup-container')
    }
  
    function handleClickOutside(mapViewController) {
      mapViewController.removeAllMarkers();
  
    }*/

  return (
    <div id="pointr-container" className="pointr-container">
    </div>
  );
};

export default MapComponent;
