// SelectedMarker.js
import React from 'react';

const SelectedMarker = () => (
<svg width="14" height="33" viewBox="0 0 14 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.89014 9.69373C8.73514 9.75432 10.4281 8.68161 11.1615 6.98874C11.8949 5.29586 11.5199 3.32566 10.2152 2.02223C8.91037 0.717451 6.94156 0.342265 5.24866 1.07587C3.55578 1.80951 2.48307 3.50238 2.54238 5.34626C2.61845 7.71475 4.52162 9.61748 6.89014 9.69373Z" fill="white"/>
<path d="M12.2558 10.6211H1.82138C0.88274 10.6211 0.12207 11.3818 0.12207 12.3204V21.1962C0.12207 22.1348 0.882773 22.8968 1.82267 22.8955H2.16175C2.9134 22.8968 3.52327 23.5067 3.52327 24.2583V30.5939C3.52327 31.5326 4.28397 32.2945 5.22258 32.2945H8.62391C9.56255 32.2945 10.3245 31.5326 10.3245 30.5939V24.2583C10.3245 23.5067 10.9344 22.8968 11.686 22.8955H12.2559C13.1946 22.8955 13.9539 22.1348 13.9539 21.1962V12.3204C13.9539 11.3831 13.1944 10.6224 12.2558 10.6211Z" fill="white"/>
</svg>
);

export default SelectedMarker;
