import React from 'react';
import CiscoLiveMainContainer from './CiscoLiveMainContainer';

const CiscoLiveFullPageContainer = () => {
  return (
    <div className="full-page-container">
       <CiscoLiveMainContainer />
    </div>
  );
};

export default CiscoLiveFullPageContainer;
