import React, { useState, useEffect, useRef } from 'react';
import '../../../../scss/search.scss'; // Import CSS for styling
import searchIcon from '../../../../assets/images/search-icon.png';
import leftArrow from '../../../../assets/images/left-arrow.png';
import { fetchSearchResults } from './searchApis';

const SearchPopup = ({ setSearchInput, setShowSearch, map, tenantId,placeholder}) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]); // Store suggestions here
  const [isExpanded, setIsExpanded] = useState(false);
  // Array to keep track of expanded states for each suggestion
  const [expandedIndices, setExpandedIndices] = useState();
  const [expandedIndex, setExpandedIndex] = useState(null);

// Toggle expand state for a specific suggestion
const toggleReadMore = (index) => {
  setExpandedIndex((prevIndex) =>
    prevIndex === index ? null : index // If the same item is clicked, collapse it. Otherwise, expand the new one.
  );
};

  const inputRef = useRef(null);
  useEffect(() => {
    // Add or remove the `with-suggestions` class based on suggestions length
    const searchContainer = document.querySelector('.search-container');
    if (searchContainer) {
      if (suggestions.length > 0) {
        searchContainer.classList.add('with-suggestions');
      } else {
        searchContainer.classList.remove('with-suggestions');
      }
    }
  }, [suggestions]);

  useEffect(() => {
    inputRef.current.focus();
    showVirtualKeyboard();
  }, []);
  // Fetch suggestions based on input
  useEffect(() => {
    if (inputValue) {
      handleSearchSubmit(inputValue);
    } else {
      // setFilteredSuggestions([]);
    }
  }, [inputValue]);

  const handleSearchSubmit = async (inputValue) => {
    setSearchInput(inputValue);

    const pointrWeb = map;
    const site = pointrWeb.getCurrentSite();
    const searchResults = await fetchSearchResults(tenantId, { q: inputValue });
   /**  // Separate sessions and non-sessions
    const sessions = searchResults.filter(item => item.type === 'session');
    const nonSessions = searchResults.filter(item => item.type !== 'session');

    // Sort sessions by utcStartTime
    const sortedSessions = sessions.sort((a, b) => a.utcStartTime - b.utcStartTime);

    // Combine sorted sessions with non-sessions
    const sortedData = [...sortedSessions, ...nonSessions];
  */
    setSuggestions(searchResults);
    console.log(searchResults);
    

  };

  const handleClosePopup = () => {
    setShowSearch(false);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      //handleSearchSubmit();
    }
  };

  const handleIconClick = () => {
    handleSearchSubmit();
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchInput(suggestion); // Assuming the suggestion object has a 'name' field
    setInputValue(suggestion);
    setShowSearch(false); // Hide the popup after selecting a suggestion
  };

  // Helper function to wrap matching text in a span with a highlighted style
  const getHighlightedText = (text, highlight) => {
    if (!highlight) return text;
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} className="highlighted-text">{part}</span>
      ) : (
        part
      )
    );
  };

  const getHighlightedTextForSentence = (text, highlight) => {
    if (!highlight) return text;
    
    // Escape special regex characters in the highlight string to prevent unexpected matches
    const escapedHighlight = highlight?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    
    // Use a case-insensitive regular expression to split around the highlight phrase
    const parts = text?.split(new RegExp(`(${escapedHighlight})`, 'gi'));
    
    return parts?.map((part, index) =>
      part?.toLowerCase() === highlight?.toLowerCase() ? (
        <span key={index} className="highlighted-text">{part}</span>
      ) : (
        part
      )
    );
  };

  const handleClearClick = () => {
    setInputValue('');
    setSearchInput('');
    setSuggestions([]);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const showVirtualKeyboard = () => {
    inputRef.current.focus(); // Focus again to ensure virtual keyboard appears
  };

  const shouldApplyMask = (suggestion) => {
    return (
      (suggestion.metadata && suggestion.metadata.length > 10) ||
      (suggestion.description && suggestion.description.length > 20)
    );
  };
  return (
    <div className="search-popup-overlay">
      <div className='search-container'>
        <div className="search-container-search-popup">
          <div className="search-container-search-popup-search-input">
            <div className="search-container-search-popup-search-input-container">
              <img
                src={searchIcon}
                alt="Search"
                className="search-container-search-popup-search-input-container-search-icon"
              />
              <input
                ref={inputRef}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder={placeholder ? placeholder:"Search for zones, booths, hub walks and other facilities"}
              />
              {inputValue && (
                <span className="clear-text" onClick={handleClearClick}>
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.17229 11.3329L0.157501 18.3477L3.6649 21.8551L10.6797 14.8403L17.6945 21.8551L21.2019 18.3477L14.1871 11.3329L21.2019 4.31809L17.6945 0.81069L10.6797 7.82548L3.6649 0.810689L0.157502 4.31808L7.17229 11.3329Z" fill="white" />
                  </svg>

                </span>
              )}
            </div>
            <div className="search-container-search-popup-search-input-clear-text" onClick={handleClosePopup}>
              Cancel
            </div>
          </div>
          {suggestions.length > 0 && (
            <div className={`search-container-suggestions-list ${suggestions.length > 0 ? 'visible' : ''}`}
            >
              <div className='search-container-suggestions-list-results-count'>{suggestions.length} results found</div>
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="search-container-suggestions-list-suggestion-item"
                >
                  <div className="content">
                    <div
                      className="search-container-suggestions-list-suggestion-item-name"
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {getHighlightedText(suggestion.name, inputValue)}
                    </div>
                    <div>
                      <div
                        className={`category ${expandedIndex === index ? 'expanded' : ''
                          } ${(shouldApplyMask(suggestion)&&!(expandedIndex === index)) ? 'masked-content' : ''}`}
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion.metadata
                          ? suggestion.metadata.join(', ')
                          : getHighlightedTextForSentence(suggestion.description, inputValue)}
                      </div>
                      {shouldApplyMask(suggestion) && <div
                        className="search-container-suggestions-list-suggestion-item-read-more"
                        onClick={() => toggleReadMore(index)}
                      >
                        {expandedIndex === index ? 'Read Less ▲' : 'Read More ▼'}
                      </div>}
                    </div>
                  </div>
                  <div
                    className="search-container-suggestions-list-suggestion-item-arrow-button"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    <svg
                      width="14"
                      height="22"
                      viewBox="0 0 14 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.31046 18.6286L8.94894 10.9902L1.31046 3.35168C0.542673 2.58389 0.542673 1.34362 1.31046 0.575839C2.07824 -0.191946 3.31851 -0.191946 4.0863 0.575839L13.1225 9.61208C13.8903 10.3799 13.8903 11.6201 13.1225 12.3879L4.0863 21.4242C3.31851 22.1919 2.07824 22.1919 1.31046 21.4242C0.56236 20.6564 0.542673 19.3964 1.31046 18.6286Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              ))}
            </div>)}
        </div>

      </div>
    </div>
  );
};

export default SearchPopup;
