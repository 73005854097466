// src/utils/facilityMapping.js

import HubTalkTheaters from '../../../../assets/images/facilities/hub-talk-theaters.svg';
import Cafeteria from '../../../../assets/images/facilities/cafes.svg';
import Lounges from '../../../../assets/images/facilities/lounges.svg';
import Restrooms from '../../../../assets/images/facilities/rest-rooms.svg';
import Registrations from '../../../../assets/images/facilities/registration.svg';
import CiscoStore from '../../../../assets/images/facilities/cisco-store.svg';
import ProfessionalPhotos from '../../../../assets/images/facilities/professional-photos.svg';
import Entry from '../../../../assets/images/facilities/entry.svg';
import Exit from '../../../../assets/images/facilities/exit.svg';

const iconMapping = {
  CiscoStore: CiscoStore,
  HubTalkTheaters: HubTalkTheaters,
  Cafeteria: Cafeteria,
  Lounges: Lounges,
  Restrooms: Restrooms,
  Registrations: Registrations,
  ProfessionalPhotos: ProfessionalPhotos,
  Entry : Entry ,
  Exit:  Exit
};

const facilityNameMapping = {
  'Cisco Store':["ciscostore","CiscoStore"],
  'Professional Photos':["professionalphotos","ProfessionalPhotos"],
  'Entry':["entry","Entry"],
  'Exit':["exit","Exit"],
  'Cafes': ["cafes", "Cafe-normal"],
  'Hub Talk Theaters': ["theatre", "HubTheater"],
  'Lounges': ["lounges", "Lounge"],
  'Restrooms': ["restrooms", "Restroom-n"],
  'Registrations': ["registration", "Registration"]
};

// Function to find common levels
const findCommonLevels = (buildings) => {
  if (!buildings || buildings.length === 0) return [];

  // Start with the levels from the first building
  let commonLevels = buildings[0].levels;

  // Iterate through the rest of the buildings and find common levels
  for (let i = 1; i < buildings.length; i++) {
    const currentBuildingLevels = buildings[i].levels;
    commonLevels = commonLevels.filter(level =>
      currentBuildingLevels.some(currentLevel => level.levelIndex === currentLevel.levelIndex)
    );
  }
  if (commonLevels.length > 1) {
    return commonLevels.reduce((lowest, level) => {
      return level.levelIndex < lowest.levelIndex ? level : lowest;
    });
  }

  return commonLevels;
};

function listAllAvailableLevels(buildings) {
  if (!buildings || buildings.length === 0) return [];

  const uniqueLevels = new Set(); // To hold unique level indices
  const levelMap = new Map(); // To hold levels by their indices

  // Iterate through each building
  for (let i = 0; i < buildings.length; i++) {
    const currentBuildingLevels = buildings[i].levels;

    // Iterate through each level in the current building
    for (let level of currentBuildingLevels) {
      if (!uniqueLevels.has(level.levelIndex)) {
        uniqueLevels.add(level.levelIndex); // Track unique level indices
        levelMap.set(level.levelIndex, level); // Map levelIndex to the level object
      }
    }
  }

  // Convert the levelMap values to an array and sort it by levelIndex
  const sortedLevels = Array.from(levelMap.values()).sort((a, b) => a.levelIndex - b.levelIndex);
  
  return sortedLevels;
}

function setLogoBackground(imageName) {
 
  const headerLogoComponent = document.getElementById("header-container-card-1-left-logo");

  if ( headerLogoComponent) {
    headerLogoComponent.style.backgroundImage = `url('/img/${imageName}')`;
  }

}
export { iconMapping, facilityNameMapping, findCommonLevels ,setLogoBackground, listAllAvailableLevels};
