import React from 'react';
import '../../../../scss/common.scss'; // Import CSS file for styling
import searchIcon from '../../../../assets/images/search-icon.png';
import refreshIcon from '../../../../assets/images/refresh-icon.png';
import {handleWhereAmI} from './handle-where-am-i';  
import whereAmIIcon from '../../../../assets/images/where-am-i-button.svg'; // Import Where Am I icon
import { useMap } from '../../../context/MapContext'; // Import the useMap hook
import { useLocation } from 'react-router-dom';

const FooterContainer = ({
  setShowSearch,
  handleResetView,long,lat,rotation, pitch, bearing, whereAmILevel, whereAmIBuilding
}) => {
  const { map } = useMap();
  
  return (
    <div className="action-buttons-container">
      <div className="search-view">
        <button className="search-button" onClick={() => setShowSearch(true)}>
          <img src={searchIcon} alt="Search Icon" className="button-icon" />
          Search
        </button>
        
      </div>
      <div className="vertical-line"></div>
      <div className="where-am-i-view">
      <button className="where-am-i-button" onClick={handleWhereAmI(map,long,lat,rotation,pitch,bearing,whereAmILevel, whereAmIBuilding)}>        
        <img src={whereAmIIcon} alt="Marker Icon" className="where-am-i-button-marker-icon" />
          <span className="button-content">
            Where Am I ?
          </span>
      </button>
      </div>
      <div className="vertical-line"></div>
      <div className="reset-view">
        <button className="reset-button" onClick={() => handleResetView(false)}>
          <img src={refreshIcon} alt="Refresh Icon" className="refresh-icon" />
          Reset View
        </button>
      </div>
    </div>
  );
};

export default FooterContainer;
