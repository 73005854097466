// ClockWidget.js
import React, { useState, useEffect } from 'react';

const ClockWidget = ({ onTimeUpdate,eventTimeZone }) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timerId = setInterval(() => {
      const now = new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZone: eventTimeZone,
      }).format(new Date())
      setCurrentTime(now);
      onTimeUpdate(now);
    }, 10000); // Update every second

    return () => clearInterval(timerId);
  }, [onTimeUpdate]);

  return null; // This component does not render anything itself
};

export default ClockWidget;
