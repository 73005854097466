// layerUtils.js

export const updateLayerFilterForSingleFeature = (mapView, feature) => {
    const layerId = "rmaps-booth-marker";
    const sourceId = "source_ptr";

    // Retrieve the existing layer
    const layer = mapView.getLayer(layerId);

    if (layer) {
        // Create a new filter array based on the existing filter
        const newFilter = [
            "all",
            ["==", "type", "marker"],
            ["any", ["==", "sType", "booth"]],
            ["==", "lvl", 1],
            ["!=", "name", feature.properties.name]
        ];

        // Remove the existing layer
        mapView.removeLayer(layerId);

        // Re-add the layer with the updated filter
        mapView.addLayer({
            "id": layerId,
            "type": "symbol",
            "source": sourceId,
            "source-layer": "maps-layer",
            "paint": {
                "text-halo-blur": 0.5,
                "text-halo-color": "rgba(255, 255, 255, 1)",
                "text-halo-width": 1,
                "text-color": "#000"
            },
            "filter": newFilter,
            "layout": {
                "text-font": ["DIN Pro Bold"],
                "visibility": "visible",
                "text-size": 13,
                "icon-text-fit": "none",
                "symbol-z-order": "auto",
                "icon-offset": [0, -30],
                "text-offset": [0, -2],
                "icon-image": "BoothMarker",
                "text-field": "{name}",
                "text-max-width": 5,
                "text-line-height": 1,
                "text-letter-spacing": -0.030,
                "icon-size": 0.80,
                "icon-allow-overlap": false,
                "text-allow-overlap": false,
                "icon-keep-upright": true,
                "icon-anchor": "bottom",
                "text-anchor": "top",
                "icon-optional": false,
                "symbol-avoid-edges": true,
                "icon-ignore-placement": false,
                "text-ignore-placement": false,
                "text-pitch-alignment": "viewport",
                "icon-pitch-alignment": "viewport"
            },
            "minzoom": 20,
            "maxzoom": 24
        });
    }
};

export const updateLayerFilterForMultipleFeature = (mapView, features) => {
    const layerId = "rmaps-booth-marker";
    const sourceId = "source_ptr";

    // Retrieve the existing layer
    const layer = mapView.getLayer(layerId);

    if (layer) {
        // Create a new filter array based on the existing filter
        const nameFilters = [
            "any",
            ...features.map(feature => ["!=", "name", feature.properties.name])
          ];
        const newFilter = [
            "all",
            ["==", "type", "marker"],
            ["any", ["==", "sType", "booth"]],
            ["==", "lvl", 1],
            nameFilters
        ];

        // Remove the existing layer
        mapView.removeLayer(layerId);

        // Re-add the layer with the updated filter
        mapView.addLayer({
            "id": layerId,
            "type": "symbol",
            "source": sourceId,
            "source-layer": "maps-layer",
            "paint": {
                "text-halo-blur": 0.5,
                "text-halo-color": "rgba(255, 255, 255, 1)",
                "text-halo-width": 1,
                "text-color": "#000"
            },
            "filter": newFilter,
            "layout": {
                "text-font": ["DIN Pro Bold"],
                "visibility": "visible",
                "text-size": 13,
                "icon-text-fit": "none",
                "symbol-z-order": "auto",
                "icon-offset": [0, -30],
                "text-offset": [0, -2],
                "icon-image": "BoothMarker",
                "text-field": "{name}",
                "text-max-width": 5,
                "text-line-height": 1,
                "text-letter-spacing": -0.030,
                "icon-size": 0.50,
                "icon-allow-overlap": true,
                "text-allow-overlap": true,
                "icon-keep-upright": true,
                "icon-anchor": "bottom",
                "text-anchor": "top",
                "icon-optional": false,
                "symbol-avoid-edges": true,
                "icon-ignore-placement": false,
                "text-ignore-placement": false,
                "text-pitch-alignment": "viewport",
                "icon-pitch-alignment": "viewport"
            },
            "minzoom": 20,
            "maxzoom": 24
        });
    }
};

export const resetBoothLayerFilter = (mapView) => {
    const layerId = "rmaps-booth-marker";
    const sourceId = "source_ptr";

    // Retrieve the existing layer
    const layer = mapView.getLayer(layerId);

    if (layer) {
        // Create a new filter array based on the existing filter
        const newFilter = [
            "all",
            ["==", "type", "marker"],
            ["any", ["==", "sType", "booth"]],
            ["==", "lvl", 1]
        ];

        // Remove the existing layer
        mapView.removeLayer(layerId);

        // Re-add the layer with the updated filter
        mapView.addLayer({
            "id": "rmaps-booth-marker",
            "type": "symbol",
            "source": sourceId,
            "source-layer": "maps-layer",
            "paint": {
                "text-halo-blur": 0.5,
                "text-halo-color": "rgba(255, 255, 255, 1)",
                "text-halo-width": 1,
                "text-color": "#000"
            },
            "filter": newFilter,
            "layout": {
                "text-font": ["DIN Pro Bold"],
                "visibility": "visible",
                "text-size": 13,
                "icon-text-fit": "none",
                "symbol-z-order": "auto",
                "icon-offset": [0, -30],
                "text-offset": [0, -2],
                "icon-image": "BoothMarker",
                "text-field": "{name}",
                "text-max-width": 5,
                "text-line-height": 1,
                "text-letter-spacing": -0.030,
                "icon-size": 0.80,
                "icon-allow-overlap": false,
                "text-allow-overlap": false,
                "icon-keep-upright": true,
                "icon-anchor": "bottom",
                "text-anchor": "top",
                "icon-optional": false,
                "symbol-avoid-edges": true,
                "icon-ignore-placement": false,
                "text-ignore-placement": false,
                "text-pitch-alignment": "viewport",
                "icon-pitch-alignment": "viewport"
            },
            "minzoom": 20,
            "maxzoom": 24
        });
        mapView.setLayoutProperty("rmaps-symbol_selected_ptr", 'icon-image', 'BoothMarker');
    }
};