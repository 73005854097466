import React from 'react';
import MainContainer from './MainContainer';

const FullPageContainer = () => {
  return (
    <div className="full-page-container">
       <MainContainer />
    </div>
  );
};

export default FullPageContainer;
