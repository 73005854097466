import React from 'react';

const ArrowIcon = ({ direction }) => (
  <svg
    margin-top="15"
    width="25"
    height="40"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`arrow-icon ${direction}`}
  >
    {direction === 'up' ? (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9998 10.6669C10.3038 10.6669 10.6065 10.7695 10.8532 10.9762L18.8532 17.6429C19.4185 18.1135 19.4958 18.9549 19.0238 19.5202C18.5531 20.0855 17.7131 20.1615 17.1465 19.6909L9.9852 13.7229L2.8358 19.4762C2.2625 19.9375 1.4225 19.8468 0.961148 19.2735C0.499799 18.7002 0.590457 17.8615 1.16379 17.3988L9.16379 10.9615C9.40779 10.7655 9.70379 10.6669 9.9998 10.6669Z"
        fill="white"
      />
    ) : (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0002 9.33315C9.6962 9.33315 9.39354 9.23048 9.14687 9.02382L1.14687 2.35715C0.581538 1.88648 0.504204 1.04515 0.976204 0.479816C1.44687 -0.0855178 2.28687 -0.161518 2.85354 0.309149L10.0149 6.27715L17.1642 0.523816C17.7375 0.0624822 18.5775 0.153149 19.0389 0.726482C19.5002 1.29982 19.4095 2.13848 18.8362 2.60115L10.8362 9.03848C10.5922 9.23448 10.2962 9.33315 10.0002 9.33315Z"
        fill="white"
      />
    )}
  </svg>
);

export default ArrowIcon;
