import React, { useEffect, useRef, useState } from 'react';
import spotIcon from '../../../../assets/images/navigation-spot.svg';
import navigationPinIcon from '../../../../assets/images/navigation-pin.svg'; // Adjust the path as necessary
import navigationDot from '../../../../assets/images/navigation-dot.svg'; // Adjust the path as necessary

import phoneIcon from '../../../../assets/images/navigation-phone.svg'; // Adjust the path as necessary
import phoneBackgroundIcon from '../../../../assets/images/qr-code-zoom-background.svg'; // Adjust the path as necessary
import QrCodeComponent from '../left-container/left-qr-code-component'; // Adjust the import according to your project structure
import { useLocation } from 'react-router-dom';
import '../../../../scss/common.scss'; // Import CSS file for styling
import searchIcon from '../../../../assets/images/search-icon.png';
import whereAmIIcon from '../../../../assets/images/where-am-i-button.svg'; // Import Where Am I icon
import backIcon from '../../../../assets/images/back.svg';
import nextIcon from '../../../../assets/images/next.svg'; // Import Where Am I icon
import { useMap } from '../../../context/MapContext'; // Import the useMap hook
import { manageLayers, markCurrentPosition } from '../main-container/markCurrentPosition';
import { tree } from 'd3';

const CustomPopup = ({ poi, tenantId, locationId, coordinates, onConfirm, onCancel, source, long ,lat,rotation , building, level, whereAmIBuilding,  whereAmIlevel,  qrCodeExpiresAt,destFid, setBuilding,setLevel,isZonesPopulated=true, eventStartDate, eventTimeZone='America/Los_Angeles' ,pitch, bearing, setSelectedFloor}) => {
  const { map } = useMap();

  const [showDirectionsPopup, setShowDirectionsPopup] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(poi._properties.name);
  const [isButtonVisible, setButtonVisible] = useState(true);
  const [isQrCodeGenerated, setIsQrCodeGenerated] = useState(false);
  const [requestBody, setRequestBody] = useState({
    tenantId: tenantId,
    locationId: locationId,
  });
  const [isDescriptionVisible, setDescriptionVisible] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSessionsExpanded, setIsSessionsExpanded] = useState(false);
  const [distanceAndTime, setDistanceAndTime] = useState();

  const isInitialMount = useRef(true);
  const [isActive, setIsActive] = useState(false);
  const [nextStepsCompleted, setNextStepsCompleted] = useState(false);
  const [backStepsCompleted, setBackStepsCompleted] = useState(true);

  const handleTap = () => {
    setIsActive(!isActive);
  };

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleSessionsReadMore = () => {
    setIsSessionsExpanded(!isSessionsExpanded);
  };

  useEffect(() => {
    if (!isQrCodeGenerated) {
      setIsQrCodeGenerated(true);
    }
  }, [isQrCodeGenerated]);

  const renderStepContent = () => {
    const stepsInfo = map?.uiController?.navigationViewController?.stepsInfo;
    const stepIndex = map?.uiController?.navigationViewController?.currentStep;
    const before = stepsInfo?.length>0?((stepsInfo[stepIndex]?.message === "Custom Point")? "Current Location":stepsInfo[stepIndex]?.message):"";
    const v = stepsInfo?.length>0?((stepsInfo[stepIndex]?.message === "Custom Point")? "Current Location":stepsInfo[stepIndex]?.message):"";
    const after = stepsInfo?.length>0?((stepsInfo[stepIndex+1]?.message === "Custom Point")? "Current Location":stepsInfo[stepIndex+1]?.message):"";

    return (
      <>      
      <div className="custom-popup-navigation-popup-sub-content-input-wrapper-1-before">
      <div className="custom-popup-navigation-popup-sub-content-steps-wrapper-1-before-l">
        <input type="text" className="custom-popup-navigation-popup-sub-content-steps-wrapper-1-before-l-input" id="steps-description-before" value={before}
      />
        </div>
      </div>  
      <div className="custom-popup-navigation-popup-sub-content-input-wrapper-1">
      <img src={navigationDot} alt="Spot Icon" className="custom-popup-navigation-popup-sub-content-steps-wrapper-1-icon" />
      <div className="custom-popup-navigation-popup-sub-content-steps-wrapper-1-l">
        <input type="text" className="custom-popup-navigation-popup-sub-content-steps-wrapper-1-l-input" id="steps-description" value={v}
      />
        </div>
      </div>  
       <div className="custom-popup-navigation-popup-sub-content-input-wrapper-1-after">
       <div className="custom-popup-navigation-popup-sub-content-steps-wrapper-1-after-l">
         <input type="text" className="custom-popup-navigation-popup-sub-content-steps-wrapper-1-after-l-input" id="steps-description-after" value={after}
       />
         </div>
       </div>  
       </>

    );
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
    /**<span className="custom-popup-navigation-popup-sub-content-input-wrapper-2-input-edit" onClick={handleEditClick}>
     Edit
   </span> */
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    // Integrate search logic here
  };

  useEffect(() => {
    if (destFid) {
      handleGetDirectionsClick();
    }
  }, []);
  
  const handleGetDirectionsClick = () => {
    setShowDirectionsPopup(true);
    setButtonVisible(false);
    setDescriptionVisible(false);
    if(document.getElementById('left-container-wrapper'))
      document.getElementById('left-container-wrapper').style.display = 'none';
    document.getElementById('left-container-footer').style.display = 'none';
    document.getElementById('pointr-top-left-ui-container').style.opacity = '0';
    const pointrWeb = window.PointrWebSDK.MapWidget.getInstance();
    const navigationViewController = pointrWeb.getUiController().getNavigationViewController();
    const mapViewController = pointrWeb.getUiController().getMapViewController();
    const mapView = pointrWeb.getUiController().getMapViewController().getView();

    const startDestination = new window.PointrWebSDK.Feature(
      { lvl: whereAmIlevel },
      { type: 'Point', coordinates: [long, lat] }
    );
    setSelectedFloor(whereAmIlevel);
    navigationViewController.populate(startDestination, poi);
    navigationViewController.startNavigation();

    setTimeout(async () => {
      mapView.map.flyTo({
        center: [long,lat],
        bearing: bearing, speed: 0.4
      });
    }, 1500);
   
    processPathDataForDistanceAndTime(pointrWeb);
    setBuildingContextForInitialStep();
    setRequestBody(((new Date().getTime()/1000)>qrCodeExpiresAt) ? {
      tenantId: tenantId,
      locationId: locationId,
      poiId: poi._properties.fid
    }:{
      tenantId: tenantId,
      locationId: locationId,
      poiId: poi._properties.fid,
      expiresAt: qrCodeExpiresAt
    });
    markCurrentPosition(map, long, lat,rotation ,true ,pitch ,bearing ,whereAmIBuilding , whereAmIlevel ,whereAmIBuilding ,whereAmIlevel );
  };

  const handleNextNavigation = () => {
    if(nextStepsCompleted)
      return
    //setIsActive(!isActive);
    
    // Get stepsInfo from the SDK
    const currentRouteData = map?.uiController?.navigationViewController?.currentRouteData;
    const stepsInfo = map?.uiController?.navigationViewController?.stepsInfo;

    // Retrieve the current step index
    const currentStepIndex = map?.uiController?.navigationViewController?.currentStep + 1;
    if(stepsInfo?.length<=2){
      setNextStepsCompleted(true) 
      setBackStepsCompleted(true)
      return;
    } 
    // Check if stepsInfo and currentStepIndex are valid
    if (currentRouteData && typeof currentStepIndex === 'number' && currentRouteData[currentStepIndex]) {
      const currentStep = currentRouteData[currentStepIndex];

      // Get buildingInternalIdentifier and level if available
      const bid = currentStep[0]?.buildingInternalIdentifier || whereAmIBuilding;
      console.log(currentStep[0]?.levelIndex);
      const lvl = currentStep[0]?.levelIndex !== undefined ? currentStep[0].levelIndex : whereAmIlevel;


      const stepIndex = map?.uiController?.navigationViewController?.currentStep;

    // Set building and level based on the current step's information
    document.getElementById("steps-description-before").value = stepsInfo[stepIndex]?.message === "Custom Point"? "Current Location":stepsInfo[stepIndex]?.message
    document.getElementById("steps-description").value = stepsInfo[stepIndex+1]?.message === "Custom Point"? "Current Location":stepsInfo[stepIndex+1]?.message
    document.getElementById("steps-description-after").value =stepsInfo[stepIndex+2]?.message === "Custom Point"? "Current Location":stepsInfo[stepIndex+2]?.message

      manageLayers(window.PointrWebSDK.MapWidget.getInstance()?.getUiController().getMapViewController().getView(),bid ,lvl )
      map?.uiController?.navigationViewController?.NavigationView_onNextStepSelected()

    } else {
      console.error("Unable to retrieve stepsInfo or current step.");
      manageLayers(window.PointrWebSDK.MapWidget.getInstance()?.getUiController().getMapViewController().getView(), whereAmIBuilding, whereAmIlevel)

    }
    setNextStepsCompleted(currentRouteData && typeof currentStepIndex === 'number' && currentStepIndex >= currentRouteData.length - 1);
    setBackStepsCompleted(currentStepIndex<1);
  };

  const handleBackNavigation = () => {
    if(backStepsCompleted)
      return
    
    

     // Get stepsInfo from the SDK
     const currentRouteData = window.PointrWebSDK.MapWidget.getInstance()?.uiController?.navigationViewController?.currentRouteData;
     const stepsInfo = map?.uiController?.navigationViewController?.stepsInfo;

     const currentStepIndex = window.PointrWebSDK.MapWidget.getInstance()?.uiController?.navigationViewController?.currentStep-1;
     if(stepsInfo?.length<=2){
      setNextStepsCompleted(true) 
      setBackStepsCompleted(true)
      return;
    }
    // Check if stepsInfo and currentStepIndex are valid
    if (currentRouteData && typeof currentStepIndex === 'number' && currentRouteData[currentStepIndex]) {
      const currentStep = currentRouteData[currentStepIndex];

      // Get buildingInternalIdentifier and level if available
      const bid = currentStep[0]?.buildingInternalIdentifier || building;
      const lvl = currentStep[0]?.levelIndex ? currentStep[0]?.levelIndex  : whereAmIlevel;

      const stepIndex = map?.uiController?.navigationViewController?.currentStep;

      // Set building and level based on the current step's information
      document.getElementById("steps-description-before").value = stepsInfo[stepIndex]?.message === "Custom Point"? "Current Location": stepsInfo[stepIndex]?.message
      document.getElementById("steps-description").value = stepsInfo[stepIndex-1]?.message === "Custom Point"? "Current Location": stepsInfo[stepIndex-1]?.message
      document.getElementById("steps-description-after").value = stepsInfo[stepIndex+1]?.message === "Custom Point"? "Current Location": stepsInfo[stepIndex+1]?.message

      manageLayers(map?.getUiController().getMapViewController().getView(),bid?bid:whereAmIBuilding,lvl?lvl:whereAmIlevel)
      map?.uiController?.navigationViewController?.NavigationView_onPreviousStepSelected()
    } else {
      console.error("Unable to retrieve stepsInfo or current step.");
    }
     // Retrieve the current step index
     setNextStepsCompleted(currentRouteData && typeof currentStepIndex === 'number' && currentStepIndex >= currentRouteData.length - 1);
     setBackStepsCompleted(currentStepIndex<1);
     //setIsActive(!isActive);
  //  setBuilding(feature.properties.bid);
  //  setLevel(feature.properties.lvl);
  };


  function setBuildingContextForInitialStep() {
      manageLayers(window.PointrWebSDK.MapWidget.getInstance()?.getUiController().getMapViewController().getView(), whereAmIBuilding, whereAmIlevel)
     
  }
  useEffect(() => {
    const checkStepsInfo = () => {
      const stepsInfo = map?.uiController?.navigationViewController?.stepsInfo;

      // Check if stepsInfo is defined and has items
      if (stepsInfo && stepsInfo.length > 0) {
         updateDescriptions(stepsInfo);
         map?.uiController?.mapViewController?.removeAllCustomPointMarkersFromMap();
      }  
    };

    // Run the check immediately
    checkStepsInfo();

    // Set an interval to check periodically
    const intervalId = setInterval(checkStepsInfo, 1000); // Check every 1 second

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [map]); // Depend on `map` to trigger re-checks when it updates

  const updateDescriptions = (stepsInfo) => {
    if (document.getElementById("steps-description-before")) {
      if(document.getElementById("steps-description-before").value==="")
        document.getElementById("steps-description-before").value = stepsInfo[0]?.message === "Custom Point"? "Current Location" : stepsInfo[0]?.message|| "";
    }
    if (document.getElementById("steps-description")) {
      if(document.getElementById("steps-description").value==="")
        document.getElementById("steps-description").value = stepsInfo[0]?.message === "Custom Point"? "Current Location" : stepsInfo[0]?.message|| "";
    }
    if (document.getElementById("steps-description-after")) {
      if(document.getElementById("steps-description-after").value==="")
        document.getElementById("steps-description-after").value = stepsInfo[1]?.message === "Custom Point"? "Current Location" : stepsInfo[1]?.message|| "";
    }

    if(stepsInfo?.length<=2){
      setNextStepsCompleted(true) 
      setBackStepsCompleted(true)
    }  
    // Additional logic can be added here for your application
};


  function getFormattedDateForUtcTime(utcTimestamp) {
    const date = new Date(utcTimestamp);

    // Get the date parts in the specified time zone
    const day = new Intl.DateTimeFormat('en-US', { timeZone: eventTimeZone, day: 'numeric' }).format(date);
    const month = new Intl.DateTimeFormat('en-US', { timeZone: eventTimeZone, month: 'long' }).format(date);
    const year = new Intl.DateTimeFormat('en-US', { timeZone: eventTimeZone, year: 'numeric' }).format(date);

    // Add the suffix to the day
    const daySuffix = getDaySuffix(parseInt(day));

    const formattedDate = `${day}${daySuffix} ${month}, ${year}`;

    return formattedDate;
  }

  function getFormattedDate() {
    const baseDay = eventStartDate.day;
    const currentDate = new Date();
    const targetDate = new Date(currentDate.getFullYear(), eventStartDate.month, baseDay); // 7 is August (months are 0-indexed)

    // Check if the current date is before 27th August
    if (currentDate < targetDate) {
      currentDate.setDate(baseDay);
      currentDate.setMonth(eventStartDate.month); // August
    }
    const day = currentDate.getDate();
    const month = currentDate.toLocaleString('en-US', { month: 'long' });
    const year = currentDate.getFullYear();

    // Get the appropriate suffix for the day
    const daySuffix = getDaySuffix(day);

    // Format the date as "27th August, 2024"
    const formattedDate = `${day}${daySuffix} ${month}, ${year}`;

    return formattedDate;
  }
  async function processPathDataForDistanceAndTime(pointrWeb) {
    try {
    
      const cachedPromises = pointrWeb.getUiController().getNavigationViewController().dataManager.networkCoordinator.cachedPromises;
      // Get the path object
      const path = getPathObject(cachedPromises, `${window.env.BACKEND_API_URL}/wayfinder/api/v8/path`);

      // Check if path object is valid
      if (!path || !path._result || !path._result.result) {
        console.error("No path present")
        return;
      }

      const result = path._result.result;
      const travelTimeSeconds = result.travelTimeSeconds;
      const walkingDistanceMeters = result.walkingDistanceMeters;

      // Format the output
      const formattedOutput = formatDurationAndDistance(travelTimeSeconds, walkingDistanceMeters);

      // Update the UI or handle the formatted output
      setDistanceAndTime(formattedOutput);
      //setStepIndex(pointrWeb.uiController.navigationViewController.stepsInfo.length)
     // setStepInfo(pointrWeb.uiController.navigationViewController.stepsInfo.length)


    } catch (error) {
      // Log the error to the console
      console.error('Error processing path data:', error);

      // Optionally, you can update the UI to indicate an error
      setDistanceAndTime('');
    }
  }

  function formatDurationAndDistance(seconds, distanceMeters) {
    // Convert seconds to minutes
    let timeFormatted = seconds < 60
      ? 'Less than 1 min'
      : `Less than ${Math.round(seconds / 60)} min`;

    // Convert meters to feet
    const distanceFeet = distanceMeters * 3.28084;
    const distanceFormatted = `${Math.round(distanceFeet)} ft`;

    // Return formatted string
    return `${timeFormatted} | ${distanceFormatted}`;
  }
  function getPathObject(cachedPromises, pathToCheck) {
    let latestPath = null;
    let latestTimestamp = -1;

    for (const key in cachedPromises) {
    if (key.includes(pathToCheck)) {
        const promiseData = cachedPromises[key];

        // Check if promiseData and its _result have createdTimestampUtcEpochSeconds
        if (promiseData && promiseData._result && promiseData._result.createdTimestampUtcEpochSeconds) {
          const timestamp = promiseData._result.createdTimestampUtcEpochSeconds;

          // Update latestPath if this one is more recent
          if (timestamp > latestTimestamp) {
            latestTimestamp = timestamp;
            latestPath = promiseData;
          }
        }
      }
    }
    return latestPath; // Return the latest promise associated with the matching key, or null if none found
  }

  function getDaySuffix(day) {
    if (day > 3 && day < 21) return 'th'; // 4th to 20th are all 'th'
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }
  const handleDirectionsPopupClose = () => {
    if(document.getElementById('left-container-wrapper'))
      document.getElementById('left-container-wrapper').style.display = 'block';
    document.getElementById('left-container-footer').style.display = 'flex';

    const startDestination = new window.PointrWebSDK.Feature(
      { lvl: level },
      { type: 'Point', coordinates: [long, lat] }
    );
    onCancel();
    setShowDirectionsPopup(false);
    const pointrWeb = window.PointrWebSDK.MapWidget.getInstance();
    const uiController = pointrWeb.getUiController();
    const mapViewController = uiController.getMapViewController();
    const mapView = mapViewController.getView();
    pointrWeb.unhighlight();
    mapViewController.unhighlightAllPois();
    mapViewController.removeAllMarkers();
    mapViewController.removeAllCustomPointMarkersFromMap();
    // resetBoothLayerFilter(mapView);
    const navigationViewController = pointrWeb.getUiController().getNavigationViewController();
    pointrWeb.getUiController().getMapViewController().hideRoute();
    // navigationViewController.resetNavigation();
    navigationViewController.close();
    setSelectedFloor(whereAmIlevel);
  };

  function convertUTCToEventTimeZone(utcTime) {
    const date = new Date(utcTime);
    const options = { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: eventTimeZone };
    return date.toLocaleString('en-US', options);
  }

  function SessionDetails({ session }) {
    const { utcStartTime, utcEndTime } = session;

    const startTime = convertUTCToEventTimeZone(utcStartTime);
    const endTime = convertUTCToEventTimeZone(utcEndTime);

    return (
      <div className="schedule-time-column">
        <div className="schedule-time-column-time">{startTime.toLowerCase()}</div>
        <div className="schedule-time-column-division">-</div>
        <div className="schedule-time-column-time">{endTime.toLowerCase()}</div>
      </div>
    );
  }
  return (
    <div className={`custom-popup ${showDirectionsPopup ? 'custom-popup-left' : ''}`}>
      <button className="custom-popup-close-button" onClick={handleDirectionsPopupClose}>
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12.1948" cy="12.4475" r="11.6699" stroke="white" strokeWidth="0.765237" />
          <path d="M16.021 15.1063L13.3624 12.4472L16.021 9.78825L14.854 8.62109L12.1951 11.2799L9.53632 8.62109L8.36917 9.78825L11.0281 12.4472L8.36865 15.1063L9.5357 16.2734L12.1951 13.6141L14.854 16.2734L16.021 15.1063Z" fill="white" />
        </svg>
      </button>
      <div className="custom-popup-header">
        <div className="custom-popup-header-popup-sub-heading-zone-name"> {poi?._properties?.extra?.ZoneValue ? 'ZONE : ' + poi._properties.extra.ZoneValue.toUpperCase() : ''}  </div>
        <h3 className={`custom-popup-header-popup-title${showDirectionsPopup?'-shrink':''}`}>{poi._properties.name}</h3>
        {isButtonVisible && poi._properties.isSession && (
          <div className="custom-popup-scheduled-sessions">
            <div className="custom-popup-scheduled-sessions-text">Scheduled Sessions</div>
            <div className="custom-popup-scheduled-sessions-date">
              {poi._properties?.sessionStart
                ? (console.log("sessionStart exists:", poi._properties.sessionStart), getFormattedDateForUtcTime(poi._properties.sessionStart))
                : (console.log("sessionStart is missing, using fallback"), getFormattedDate())
              }
            </div>
          </div>)}
        {isButtonVisible && poi._properties.isSession ? (
          <div >
            <div className={`session-tables ${isSessionsExpanded ? 'expanded' : ''}`}>
              {poi?._properties?.sessionsInfo?.map((session, index) => (
                <div className="session-table" key={index}>
                  <div className="session-code-text">Session Code :</div>
                  <div className="session-code">{session.sessionCode}</div>
                  <div className="session-name-value">{session.name || ""}</div>
                  {session.speakers && (
                    <div className="speaker-name-text">
                      {poi?._properties?.speaker || (session.speakers.length > 1 ? "SPEAKERS" : "SPEAKER")}
                    </div>
                  )}
                  <div className="speaker-info">
                    <div className="speaker-name">
                      {session.speakers?.map(speaker => speaker.name).filter(name => name).join(", ")}
                    </div>

                  </div>
                  <SessionDetails session={session} />
                </div>

              ))}
            </div>
            {(poi?._properties?.sessionsInfo?.length > 0) &&
              <div className="session-tables-read-more" onClick={toggleSessionsReadMore}>
                {isSessionsExpanded ? 'Show Less ▲' : 'Show More ▼'}
              </div>
            }
          </div>) : (
          isButtonVisible && (poi._properties.description &&
            <div>
              <div className={`custom-popup-header-description ${isExpanded ? 'expanded' : ''}`}>
                {poi._properties.description ? poi._properties.description : ''}
              </div>
              <div className="custom-popup-header-read-more" onClick={toggleReadMore}>
                {isExpanded ? 'Read Less ▲' : 'Read More ▼'}
              </div>
            </div>
          )
        )}
      </div>
      <div className="custom-popup-buttons">
        {isButtonVisible && (
          <button className="custom-popup-buttons-get-directions-button" id="popup-get-directions-button" onClick={handleGetDirectionsClick}>
            Get Directions
          </button>
        )}
      </div>
      {showDirectionsPopup && (
        <div className="custom-popup-navigation-popup">
          <div className="custom-popup-navigation-popup-header">
            <span className="custom-popup-navigation-popup-header-text">Directions</span>
            {(distanceAndTime && distanceAndTime.trim() !== '') && (
              <div className='custom-popup-navigation-popup-sub-content-text'>({distanceAndTime})</div>
            )}          
          </div>
          <div className="custom-popup-navigation-popup-sub-content">
            <div className="line" />

            <div className="custom-popup-navigation-popup-sub-content-input-wrapper-1">
              <img src={spotIcon} alt="Spot Icon" className="custom-popup-navigation-popup-sub-content-input-wrapper-1-icon" />
              <div className="custom-popup-navigation-popup-sub-content-input-wrapper-1-l">
                <span className="custom-popup-navigation-popup-sub-content-input-wrapper-1-l-label">FROM</span>
                <input type="text" className="custom-popup-navigation-popup-sub-content-input-wrapper-1-l-input" placeholder="Current Location" value='Current Location'
                  readOnly={!isEditing}
                />
              </div>
            </div>
            
            {renderStepContent()}
            <div className="custom-popup-navigation-popup-sub-content-input-wrapper-2">
              <img src={navigationPinIcon} alt="Navigation Pin Icon" className="custom-popup-navigation-popup-sub-content-input-wrapper-2-icon" />
              <div className="custom-popup-navigation-popup-sub-content-input-wrapper-2-l">
                <span className="custom-popup-navigation-popup-sub-content-input-wrapper-2-l-label">TO</span>

                <input
                  type="text"
                  className="custom-popup-navigation-popup-sub-content-input-wrapper-2-l-input"
                  placeholder="Enter text here"
                  value={inputValue}
                  readOnly={!isEditing}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="navigation-buttons-container">
              <button   className={`navigation-buttons-container-back-button ${backStepsCompleted ? 'disabled' : ''}`}
 onClick={handleBackNavigation} disabled={backStepsCompleted}>
                <img src={backIcon} alt="Back Icon" className="button-icon" />
                Back
              </button>
              <button   className={`navigation-buttons-container-next-button ${nextStepsCompleted ? 'disabled' : ''}`}  onClick={handleNextNavigation} disabled={nextStepsCompleted}>
                <img src={nextIcon} alt="Next Icon" className="button-icon" />
                Next
              </button>
            </div>
          </div>
          {isActive && <div className="background-blur-getdirections-qr" />}
          {isActive ? (
            <div className={`custom-popup-navigation-popup-footer${isActive ? '-active' : ''}`} onClick={handleTap}>
              <div className="custom-popup-navigation-popup-footer-bottom">
                {<img src={isActive ? phoneBackgroundIcon : phoneIcon} className="custom-popup-navigation-popup-footer-bottom-image" alt="Navigation Screen" />}

                <QrCodeComponent
                  tenant={tenantId}
                  location={locationId}
                  requestBody={requestBody}
                  canvasId="custom-popup-navigation-popup-footer-bottom-qr-code"
                  className="custom-popup-navigation-popup-footer-bottom-qr-code"
                  isZonesPopulated={isZonesPopulated}
                />

              </div>
              <div className={`custom-popup-navigation-popup-footer${isActive ? '-active-inner-bottom' : '-inner-bottom'}`} onClick={handleTap}>{isActive && (
                <svg width="18" height="18" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12.1948" cy="12.4475" r="11.6699" stroke="white" strokeWidth="0.765237" />
                  <path d="M16.021 15.1063L13.3624 12.4472L16.021 9.78825L14.854 8.62109L12.1951 11.2799L9.53632 8.62109L8.36917 9.78825L11.0281 12.4472L8.36865 15.1063L9.5357 16.2734L12.1951 13.6141L14.854 16.2734L16.021 15.1063Z" fill="white" />
                </svg>
              )}{isActive ? 'Close' : 'Tap to Expand'}</div>
  </div>) : (
            <div className="card-container" onClick={handleTap}>
              <div className="card-container-left">
                <div className="card-container-left-header">Navigate on phone</div>
                <div className="card-container-left-text">Please close your current app before scanning QR Code.</div>
              </div>
              <div className="card-container-right">
                <div className="qr-code-overlay"></div>
                <QrCodeComponent
                  tenant={tenantId}
                  location={locationId}
                  requestBody={requestBody}
                  canvasId="card-container-right-top-qr-code-container"
                  className="card-container-right-top-qr-code-container"
                  isZonesPopulated={isZonesPopulated}
                />

                <button className="card-container-right-bottom-expand-button" onClick={handleTap}>
                  {isExpanded ? 'Close' : 'Tap to Expand'}
                </button>
              </div>
            </div>
          )}

        </div>
      )}
    </div>

  );
};
export default CustomPopup;