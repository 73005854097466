export async function fetchSearchResults(tenantId, params = {}) {

    // Convert params object to URLSearchParams for query string
    const queryString = new URLSearchParams(params).toString();
    const apiUrl = `${window.env.BACKEND_API_URL}/wayfinder/gsx/api/${tenantId}/poi/search?${queryString}`;

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',  
            headers: {
                'Content-Type': 'application/json',
                
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data.results;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export async function fetchSessionDetails(tenantId, fid, params = {}) {

    // Convert params object to URLSearchParams for query string
    const queryString = new URLSearchParams(params).toString();
    const apiUrl = `${window.env.BACKEND_API_URL}/wayfinder/gsx/api/${tenantId}/poi/fId/${fid}/sessions?${queryString}`;

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',  
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("session details"+data.results);

        return data.results.sort((a, b) => a.utcStartTime - b.utcStartTime);
    } catch (error) {
        console.error('Error fetching data:', error);
        //throw error;
        return [];
    }
}